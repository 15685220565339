footer{
    background-color: $footer-background;
}

.footer-above-wrapper{
    border-bottom: 1px solid #efefef;
    padding-bottom: 35px;
    .footer-widget-wrapper {
        @extend .container;
        position: relative;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: max-content max-content;
        padding-top: 35px;
        margin-top: 45px;
        align-items: center;

        @include tablet-md-to {
            grid-template-rows: max-content 36px max-content;
            padding-bottom: 10px;
            padding-top: 22px;
        }
    }
}

.footer-below-wrapper{
    padding-top: 17px;

}

.footer-widget-wrapper {
    display: flex;
    flex-wrap: wrap;
    .boleroLogo {
        grid-column: 1;
        grid-row: 1;
        display: block;
        width: 206px;
        height: 33px;

        @include tablet-md-to {
            width: 139px;
            height: 22px;
        }

        .image {
            max-width: 100%;
            height: auto;
        }
    }

    .socialsFooter {
        grid-column: 2;
        grid-row: 1;
        padding: 0 27px;
        display: flex;
        height: 100%;

        @include tablet-md-to {
            grid-column: 1;
            grid-row: 2;
            padding: 4px 0 0;
        }

        .ring-simple-menu--listWrapper {
            display: inline-flex;
            padding-top: 8px;
            align-items: center;

            @include tablet-md-to {
                padding: 0;
            }

            .ring-simple-menu--label {
                display: block;
                margin: 0;

                .ring-simple-menu--labelLink {
                    font-size: 0;
                    width: 22px;
                    height: 22px;
                    display: block;
                    opacity: 0.3;
                }
            }


            &:nth-child(1) {
                .ring-simple-menu--labelLink {
                    background: url('../img/instagram.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }

            &:nth-child(2) {
                .ring-simple-menu--labelLink {
                    background: url('../img/fb.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }

            &:not(:first-child) {
                margin-left: 12px;
            }
        }
    }

    .miniLogo {
        grid-column: 3;
        grid-row: 1;
        text-align: center;
        padding-bottom: 20px;

        @include tablet-md-to {
            img {
                max-width: 98px;
                height: auto;
            }
        }
    }

    .miniLogoDesc {
        grid-column: 3;
        grid-row: 1;
        align-self: end;
        display: flex;
        align-items: flex-end;

        @include ringHeaders {
            display: block;
            width: 100%;
            max-width: 100%;
            font-size: 16px;
            line-height: 1.3125;
            letter-spacing: 0;
            text-align: center;
            margin: 0;
            flex: 1 1 100%;
            font-weight: 300;
        }
    }

    .footer-menu {
        display: flex;
        @include tablet-md-to {
            flex: 1 1 100%;
            padding-bottom: 28px;
        }

        .ring-simple-menu--listWrapper {
            &:not(:first-child) {
                margin-left: 25px;

                @include tablet-md-to {
                    margin-left: 19px;
                }
            }

            .ring-simple-menu--labelLink {
                font-family: $relative-book-font;
                font-size: 16px;
                line-height: 1.125;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $black;
                text-decoration: none;
            }
        }

    }

    .disclaimer-text {

        @include tablet-md-to {
            flex: 1 1 100%;
        }

        p {
            font-size: 16px;
            line-height: 1.3125;
            letter-spacing: 0;
            text-align: right;
            margin: 0;

            @include tablet-md-to {
                text-align: left;
            }
        }
    }



    .poweredBy {
        padding-top: 5px;
        padding-bottom: 17px;
        text-align: right;
        width: 100%;
        font-size: 16px;
        line-height: 1.3125;
        letter-spacing: 0;

        @include tablet-md-to {
            padding-bottom: 65px;
            margin-bottom: 28px;
            text-align: left;
        }

        a {
            color: $black;
        }
    }
}
