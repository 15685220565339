html, body, main {
    width: 100%;
}

html, body {
    min-height: 100%;
}

body {
    width: 100%;
    height: 100%;
    min-width: 240px;
    margin: 0;
    font-family: $ring-font-family;
    font-weight: 300;
    font-size: $ring-font-size;
    background: var(--ring-color-background);
    color: var(--ring-color-text);
    overflow-x: hidden;
}

img, source {
    max-width: 100%;
    height: auto;
}

.container, .container-fluid {
    @include makeRingContainer();
}

.image-wrapper {
    display: flex;
}

.articleBox {
    .container-wrapper {
        margin-left: -($ring-gutter-width/2);
        margin-right: -($ring-gutter-width/2);
    }
}
