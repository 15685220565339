$fontsPath: 'https://ocdn.eu/ringier-ag-verticals/bolero/assets/fonts/';

@font-face {
    font-family: 'ACaslonPro';
    //src: url($fontsPath + 'ACaslonPro-Regular.eot');
    src: local('ACaslonPro-Regular'), local('ACaslonProRegular'),
    //url($fontsPath + 'ACaslonPro-Regular.eot?#iefix') format('embedded-opentype'),
    //url($fontsPath + 'ACaslonPro-Regular.woff2') format('woff2'),
    url($fontsPath + 'ACaslonPro-Regular.woff') format('woff'),
    //url($fontsPath + 'ACaslonPro-Regular.ttf') format('truetype'),
    url($fontsPath + 'ACaslonPro-Regular.svg#ACaslonPro-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ACaslonPro';
    //src: url($fontsPath + 'ACaslonPro-Bold.eot');
    src: local('ACaslonPro-Bold'), local('ACaslonProBold'),
    //url($fontsPath + 'ACaslonPro-Bold.eot?#iefix') format('embedded-opentype'),
    //url($fontsPath + 'ACaslonPro-Bold.woff2') format('woff2'),
    url($fontsPath + 'ACaslonPro-Bold.woff') format('woff'),
    //url($fontsPath + 'ACaslonPro-Bold.ttf') format('truetype'),
    url($fontsPath + 'ACaslonPro-Bold.svg#ACaslonPro-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CaslonTwoTwentyFour';
    //src: url($fontsPath + 'CaslonTwoTwentyFour_Bold.eot');
    src: local('CaslonTwoTwentyFour_Bold'), local('CaslonTwoTwentyFourBold'),
    //url($fontsPath + 'CaslonTwoTwentyFour_Bold.eot?#iefix') format('embedded-opentype'),
    url($fontsPath + 'CaslonTwoTwentyFour_Bold.woff2') format('woff2'),
    url($fontsPath + 'CaslonTwoTwentyFour_Bold.woff') format('woff'),
    url($fontsPath + 'CaslonTwoTwentyFour_Bold.ttf') format('truetype');
    //url($fontsPath + 'CaslonTwoTwentyFour_Bold.svg#CaslonTwoTwentyFour_Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CaslonTwoTwentyFour';
    //src: url($fontsPath + 'CaslonTwoTwentyFour_Book.eot');
    src: local('CaslonTwoTwentyFour_Book'), local('CaslonTwoTwentyFourBook'),
    //url($fontsPath + 'CaslonTwoTwentyFour_Book.eot?#iefix') format('embedded-opentype'),
    url($fontsPath + 'CaslonTwoTwentyFour_Book.woff2') format('woff2'),
    url($fontsPath + 'CaslonTwoTwentyFour_Book.woff') format('woff'),
    url($fontsPath + 'CaslonTwoTwentyFour_Book.ttf') format('truetype'),
    url($fontsPath + 'CaslonTwoTwentyFour_Book.svg#CaslonTwoTwentyFour_Book') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Relative-Book';
    //src: url($fontsPath + 'Relative-Book.eot');
    src: local('Relative-Book'), local('RelativeBook'),
    url($fontsPath + 'Relative-Book.eot?#iefix') format('embedded-opentype'),
    //url($fontsPath + 'Relative-Book.woff2') format('woff2'),
    url($fontsPath + 'Relative-Book.woff') format('woff');
    //url($fontsPath + 'Relative-Book.ttf') format('truetype');
    //url($fontsPath + 'Relative-Book.svg#Relative-Book') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
