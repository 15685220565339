:root {
    --admeiraStickyHeight: 100px;
}

.container-wrapper {
    .ring-grid-container {
        .ring-grid-right-box {
            flex: 0 0 0;
            padding: 0;
        }
    }
}

.admeira-ad-container {
    text-align: center;
    width: 100%;

    &.stickySlot {
        position: sticky;
        top: 45px;
        right: 0;
        width: 100%;
        will-change: transform;
        height: var(--admeiraStickyHeight);

        > div {
            display: block !important;
            position: absolute;
            top: 0;
            transform: translate(-75px, 0px);
            left: 100%;
            overflow: hidden;
            @include flProperty("max-width", 95px, 160px, 1300px, 1440px, true, true, false);
        }

        @include tablet-lg-to {
            display: none;
        }

        &.listPage {
            > div {
                transform: none;
            }
        }
    }
}
